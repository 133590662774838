import type { FormControlOption } from "@kablamooss/geo-ds-core-components";
import {
  AppAnnualWorksPlanForecastScenario,
  AppAnnualWorksPlanEvaluationScenario,
  AppBfrmpScenario,
  AppIndividualTreatmentComparisonBrigadeScenario,
  AppIndividualTreatmentComparisonScenario,
  AppModelledImpactTimeScenario,
  AppRunScenario,
  AppRunType,
  AppStatewideMaxFuelsScenario,
  AppStatewideSnapshotScenario,
  ServiceProjectTypeAttribute,
  AppRunQaStatus,
  type AppRunStatus,
  type AppRunQaStatusProperty,
} from "../.rest-hooks/types";
import {
  PROJECT_CATEGORY_TYPE_MAP,
  ProjectCategory,
  type Project,
  type ProjectType,
  type Run,
} from "../types";
import assertNever from "../utils/assertNever/assertNever";
import { getEnvironment } from "./environment";

export type ProjectParentHref = "/project" | "/statewide" | "/local";
export type ProjectRunListPageHref = "scenarios" | "treatments";

const STATUSES = ["In progress", "Completed", "Archived"];
type Status = (typeof STATUSES)[number];

export const statusItems: FormControlOption<Status>[] = [
  { label: "In progress", value: "In progress" },
  { label: "Complete", value: "Complete" },
  { label: "Archived", value: "Archived" },
];

const SCENARIO_LABEL_MAP: Record<AppRunScenario, string> = {
  [AppRunScenario.CR]: "Current Risk",
  [AppRunScenario.FR]: "Future Risk without Treatments",
  [AppRunScenario.FT]: "Future Risk with Treatments",
  [AppRunScenario.MS]: "Modelled Scenario",
  [AppRunScenario.RR]: "Risk without Treatments",
  [AppRunScenario.RT]: "Risk with Treatments",
  [AppRunScenario.FM]: "Future Modelled",
  [AppRunScenario.PM]: "Past Modelled",
};

export const getProjectRunHasAcceptance = (
  projectType: ProjectType,
  runType: AppRunType,
  scenarioType: AppRunScenario,
): boolean => {
  return (
    (projectType === "bfrmp" ||
      projectType === "statewide-maxfuels" ||
      projectType === "statewide-snapshot" ||
      projectType === "annual-works-plan-evaluation" ||
      projectType === "annual-works-plan-forecast" ||
      (projectType === "individual-treatment-comparison" &&
        scenarioType === "RT")) &&
    runType === "PhoenixPostprocessing"
  );
};

interface GetProjectRunHasAcceptanceActionProps {
  projectType: ProjectType;
  scenario: AppRunScenario;
  status: AppRunStatus;
  qaStatus: AppRunQaStatusProperty | undefined;
}

export const getProjectRunHasAcceptanceAction = ({
  projectType,
  scenario,
  status,
  qaStatus,
}: GetProjectRunHasAcceptanceActionProps): boolean => {
  if (status !== "Complete") {
    return false;
  }

  if (
    projectRunHasQAStatus(projectType, scenario) &&
    qaStatus?.rfs.status !== AppRunQaStatus.passed
  ) {
    return false;
  }

  if (
    projectRunHasQAStatus(projectType, scenario) &&
    qaStatus?.npws.status === AppRunQaStatus.rejected
  ) {
    return false;
  }

  return true;
};

export const getProjectRunAcceptanceLabel = (
  projectType: ProjectType | undefined,
): string => {
  if (!projectType) {
    return "-";
  }

  if (isStatewideProject(projectType)) {
    return "Authorise run";
  }
  return "Accept run";
};

export const getProjectRunAcceptedLabel = (
  projectType: ProjectType | undefined,
): string => {
  if (!projectType) {
    return "-";
  }

  if (isStatewideProject(projectType)) {
    return "Authorised run";
  }
  return "Accepted run";
};

/**
 * @see https://rfs.atlassian.net/wiki/spaces/NR/pages/578584584/Modelling+Scenario+types
 */
export const SCENARIO_ORDER_MAP = {
  "statewide-maxfuels": [AppStatewideMaxFuelsScenario.MS],
  "statewide-snapshot": [AppStatewideSnapshotScenario.CR],
  // TODO: Update for AWP
  "annual-works-plan-forecast": [AppAnnualWorksPlanForecastScenario.FM],
  "annual-works-plan-evaluation": [AppAnnualWorksPlanEvaluationScenario.PM],
  bfrmp: [AppBfrmpScenario.CR, AppBfrmpScenario.FR, AppBfrmpScenario.FT],
  "modelled-impact-time": [AppModelledImpactTimeScenario.MS],
  "individual-treatment-comparison": [
    AppIndividualTreatmentComparisonScenario.RR,
    AppIndividualTreatmentComparisonScenario.RT,
  ],
  "individual-treatment-comparison-brigade": [
    AppIndividualTreatmentComparisonBrigadeScenario.RR,
    AppIndividualTreatmentComparisonBrigadeScenario.RT,
  ],
} as const satisfies Record<ProjectType, ReadonlyArray<AppRunScenario>>;

export const bfrmpScenarioItems: FormControlOption<AppBfrmpScenario>[] =
  SCENARIO_ORDER_MAP.bfrmp.map((value) => ({
    label: SCENARIO_LABEL_MAP[value],
    value,
  }));

export const getScenarioLabel = (scenario: AppRunScenario) => {
  return ` ${SCENARIO_LABEL_MAP[scenario]} (${scenario})`;
};

export const getScenarioLabelWithoutAcronym = (scenario: AppRunScenario) => {
  return SCENARIO_LABEL_MAP[scenario];
};

const runTypesMap = new Map<AppRunType, string>([
  [AppRunType.Phoenix, "Fire Behaviour run"],
  [AppRunType.PhoenixPostprocessing, "Impact Analysis run"],
]);

export const getRunTypeLabel = (runType: AppRunType) => {
  return runTypesMap.get(runType);
};

export const projectTypeLabels = {
  bfrmp: "BFRMP",
  "statewide-maxfuels": "Max Fuel Loads",
  "statewide-snapshot": "Snapshot",
  "individual-treatment-comparison": "Individual Treatment Comparison",
  "individual-treatment-comparison-brigade": "Brigade Treatment Comparison",
  "modelled-impact-time": "Modelled Impact Time",
  "annual-works-plan-forecast": "AWP Forecast",
  "annual-works-plan-evaluation": "AWP Evaluation",
} as const satisfies Record<ServiceProjectTypeAttribute, string>;

export const LocalProjectTypeItems: FormControlOption<ServiceProjectTypeAttribute>[] =
  [
    {
      label: "Individual Treatment Comparison",
      value: "individual-treatment-comparison",
    },
    {
      label: "Brigade Treatment Comparison",
      value: "individual-treatment-comparison-brigade",
    },
    {
      label: "Modelled Impact Time",
      value: "modelled-impact-time",
    },
  ];

// Reversed order
const ACCEPTED_SCENARIO_PRIORITY_MAP = Object.fromEntries(
  Object.entries(SCENARIO_ORDER_MAP).map(([projectType, scenarios]) => [
    projectType,
    scenarios.slice().reverse(),
  ]),
) as Record<string, ReadonlyArray<AppRunScenario>> as {
  [P in keyof typeof SCENARIO_ORDER_MAP]: ReadonlyArray<
    (typeof SCENARIO_ORDER_MAP)[P][number]
  >;
};

/**
 * For a given project type, returns the reverse order of scenario types, in priority order for accepted for determining project scenario date
 *
 * @see https://rfs.atlassian.net/wiki/spaces/NR/pages/578584584/Modelling+Scenario+types
 *
 * @param projectType
 * @returns
 */
export function getAcceptedScenarioPriority<TProjectType extends ProjectType>(
  projectType: TProjectType,
): (typeof ACCEPTED_SCENARIO_PRIORITY_MAP)[TProjectType] {
  return ACCEPTED_SCENARIO_PRIORITY_MAP[projectType];
}

/**
 * Returns the project scenario date as per defined business rules
 *
 * @see https://rfs.atlassian.net/wiki/spaces/NR/pages/538542098/Project+Scenario+date
 *
 * @param project
 * @param runs Project runs, ordered by createdAt ascending
 */
export default function getProjectScenarioDate(
  project: Project,
  runs: readonly Run[],
) {
  const prioritisedScenarios = getAcceptedScenarioPriority(project.type);

  for (const scenario of prioritisedScenarios) {
    // NOTE: Using findLast to prioritise the IAR over FBR (if any)
    const acceptedRun = runs.findLast(
      (run) => run.scenario === scenario && run.accepted,
    );
    if (acceptedRun) return acceptedRun.modelledAt;
  }

  return runs.at(-1)?.modelledAt;
}

export const projectTypeHasAreaOfInterestDrawer = (
  projectType: ProjectType,
): projectType is "bfrmp" | "statewide-maxfuels" | "statewide-snapshot" => {
  return (
    [
      ServiceProjectTypeAttribute.bfrmp,
      ServiceProjectTypeAttribute["statewide-maxfuels"],
      ServiceProjectTypeAttribute["statewide-snapshot"],
    ] as unknown[]
  ).includes(projectType);
};

export const projectTypeHasPolygonOfInterest = (
  projectType: ProjectType,
): projectType is
  | "individual-treatment-comparison"
  | "individual-treatment-comparison-brigade"
  | "modelled-impact-time" => {
  return (
    [
      ServiceProjectTypeAttribute["individual-treatment-comparison"],
      ServiceProjectTypeAttribute["individual-treatment-comparison-brigade"],
      ServiceProjectTypeAttribute["modelled-impact-time"],
    ] as unknown[]
  ).includes(projectType);
};

export type LocalProjectType =
  (typeof PROJECT_CATEGORY_TYPE_MAP)[ProjectCategory.LOCAL][number];

export type ProjectsWithComparisonMap =
  | (typeof PROJECT_CATEGORY_TYPE_MAP)[ProjectCategory.BFRMP][number]
  | LocalProjectType;

export type StatewideProjectType =
  (typeof PROJECT_CATEGORY_TYPE_MAP)[ProjectCategory.STATEWIDE][number];

/**
 * Returns whether the project type is ITC or BRG
 */
export const isTreatmentComparisonProject = (
  projectType?: ProjectType,
): projectType is
  | "individual-treatment-comparison"
  | "individual-treatment-comparison-brigade" => {
  return (
    [
      ServiceProjectTypeAttribute["individual-treatment-comparison"],
      ServiceProjectTypeAttribute["individual-treatment-comparison-brigade"],
    ] as unknown[]
  ).includes(projectType);
};

export const isAnnualWorksPlanProject = (
  projectType?: ProjectType,
): projectType is
  | "annual-works-plan-evaluation"
  | "annual-works-plan-forecast" => {
  return (
    [
      ServiceProjectTypeAttribute["annual-works-plan-evaluation"],
      ServiceProjectTypeAttribute["annual-works-plan-forecast"],
    ] as unknown[]
  ).includes(projectType);
};

export const isStatewideProject = (
  projectType: ProjectType,
): projectType is StatewideProjectType => {
  return (
    PROJECT_CATEGORY_TYPE_MAP[
      ProjectCategory.STATEWIDE
    ] as unknown as ProjectType[]
  ).includes(projectType);
};

export const isSwfInputShown = (
  projectType: ProjectType,
  scenario: AppRunScenario,
): boolean => {
  const isSandbox = getEnvironment() === "sandbox";
  switch (projectType) {
    case ServiceProjectTypeAttribute["statewide-maxfuels"]:
      return false;

    // TC projects use SWF from treatment areas
    case ServiceProjectTypeAttribute["individual-treatment-comparison"]:
    case ServiceProjectTypeAttribute["individual-treatment-comparison-brigade"]:
    case ServiceProjectTypeAttribute["modelled-impact-time"]:
    case ServiceProjectTypeAttribute["annual-works-plan-forecast"]:
    case ServiceProjectTypeAttribute["annual-works-plan-evaluation"]:
      return true;

    case ServiceProjectTypeAttribute["statewide-snapshot"]:
      return isSandbox;

    case ServiceProjectTypeAttribute.bfrmp:
      return scenario === AppRunScenario.FT || isSandbox;

    default:
      return assertNever(projectType);
  }
};

export const projectRunHasQAStatus = (
  projectType?: ProjectType,
  scenarioType?: AppRunScenario,
): projectType is "bfrmp" | "individual-treatment-comparison" => {
  if (
    projectType === ServiceProjectTypeAttribute.bfrmp ||
    (projectType ===
      ServiceProjectTypeAttribute["individual-treatment-comparison"] &&
      scenarioType === "RT")
  ) {
    return true;
  }
  return false;
};

export const isModifiedFireHistoryRequired = (
  projectType: ProjectType,
): projectType is
  | "annual-works-plan-evaluation"
  | "annual-works-plan-forecast"
  | "statewide-snapshot" => {
  return (
    [
      ServiceProjectTypeAttribute["annual-works-plan-evaluation"],
      ServiceProjectTypeAttribute["annual-works-plan-forecast"],
      ServiceProjectTypeAttribute["statewide-snapshot"],
    ] as unknown[]
  ).includes(projectType);
};

export const hasModifiedFireHistory = (
  projectType?: ProjectType,
): projectType is
  | "annual-works-plan-evaluation"
  | "annual-works-plan-forecast"
  | "individual-treatment-comparison"
  | "individual-treatment-comparison-brigade"
  | "statewide-snapshot" => {
  return (
    [
      ServiceProjectTypeAttribute["annual-works-plan-evaluation"],
      ServiceProjectTypeAttribute["annual-works-plan-forecast"],
      ServiceProjectTypeAttribute["individual-treatment-comparison"],
      ServiceProjectTypeAttribute["individual-treatment-comparison-brigade"],
      ServiceProjectTypeAttribute["statewide-snapshot"],
    ] as unknown[]
  ).includes(projectType);
};
