import type { FormControlOption } from "@kablamooss/geo-ds-core-components";
import {
  AppNswFdr,
  AppWeatherDriver,
  type AppWeatherDataType,
  type AppWeatherPackageType,
} from "../.rest-hooks/types";
import {
  NSWFdrCatastrophic,
  NSWFdrExtreme,
  NSWFdrHigh,
  NSWFdrLowModerate,
  NSWFdrSevere,
  NSWFdrVeryHigh,
} from "../icons";

export const timeZone = "Australia/Sydney";

export const NOTES_MAX_LENGTH = 16_384;

export const DESCRIPTION_MAX_LENGTH = 60;

export const DESCRIPTION_MAX_LENGTH_ERROR_MESSAGE = `Description cannot be longer than ${DESCRIPTION_MAX_LENGTH} characters`;

export const DEFAULT_IGNITION_START_TIME = "11:00";

export const DEFAULT_IGNITION_END_TIME = "23:00";

export const WEATHER_DATA_TYPE_LABEL_MAP = {
  netcdf: "NetCDF",
  point: "Point",
} satisfies Record<AppWeatherDataType, string>;

export const WEATHER_PACKAGE_TYPE_LABEL_MAP = {
  ...WEATHER_DATA_TYPE_LABEL_MAP,
  // Unused, reserved for future use
  mixed: "Mixed",
} satisfies Record<AppWeatherPackageType, string>;

export const WEATHER_DRIVER_LABEL_MAP = {
  [AppWeatherDriver.TEMPERATURE]: "Temperature",
  [AppWeatherDriver.WIND]: "Wind",
  [AppWeatherDriver.WIND_CHANGE]: "Wind change",
} satisfies Record<AppWeatherDriver, string>;

export const NSWFDR_LABEL_MAP = {
  [AppNswFdr.LOW_MODERATE]: "Low-moderate",
  [AppNswFdr.HIGH]: "High",
  [AppNswFdr.VERY_HIGH]: "Very high",
  [AppNswFdr.SEVERE]: "Severe",
  [AppNswFdr.EXTREME]: "Extreme",
  [AppNswFdr.CATASTROPHIC]: "Catastrophic",
} as const satisfies Record<AppNswFdr, string>;

export const NSWFDR_ICON_MAP = {
  [AppNswFdr.LOW_MODERATE]: NSWFdrLowModerate,
  [AppNswFdr.HIGH]: NSWFdrHigh,
  [AppNswFdr.VERY_HIGH]: NSWFdrVeryHigh,
  [AppNswFdr.SEVERE]: NSWFdrSevere,
  [AppNswFdr.EXTREME]: NSWFdrExtreme,
  [AppNswFdr.CATASTROPHIC]: NSWFdrCatastrophic,
} satisfies Record<AppNswFdr, ReactSVGComponent>;

/**
 * NOTE: Order here is important as we use `Object.values()` to build the options list
 */
export enum WeatherType {
  PACKAGE = "package",
  FILE = "file",
}

export const WEATHER_TYPE_LABEL_MAP = {
  [WeatherType.PACKAGE]: "Weather package",
  [WeatherType.FILE]: "Weather file",
} satisfies Record<WeatherType, string>;

export const WEATHER_TYPE_OPTIONS: readonly FormControlOption<WeatherType>[] =
  Object.values(WeatherType).map((value) => ({
    label: WEATHER_TYPE_LABEL_MAP[value],
    value,
  }));
